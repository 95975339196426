@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-bone;
}

.formLabel,
thead {
  @apply text-xxs font-medium uppercase tracking-widest;
}

.baseInput {
  @apply border-ash my-1 block w-full rounded border py-2.5 text-xs duration-200 ease-in hover:border-black focus:border-black focus:ring-black;
}

.baseInputWithError {
  @apply border-red focus:ring-red focus:border-red my-1 block w-full rounded border py-2.5 text-xs;
}

.inputInFlight {
  @apply cursor-not-allowed opacity-75;
}

.btn {
  @apply focus:ring-green hover:bg-green mt-5 flex w-full rounded-full bg-black py-3 pl-5 text-sm text-white duration-200 ease-in hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2;
}

.btnInFlight {
  @apply animate-pulse cursor-wait;
}

.productHeadline {
  @apply font-display pt-3 text-2xl;
}

.btnSecondary {
  @apply items-center rounded-full border border-black bg-white px-3 py-2 text-left text-xs duration-200 ease-in;
}

.btnSecondaryActive {
  @apply hover:bg-green hover:border-green focus:ring-green cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2;
}

.btnOps {
  @apply items-center rounded-full bg-violet-500 px-3 py-2 text-left text-xs text-white duration-200 ease-in hover:bg-violet-600;
}

.btnAccent {
  @apply bg-green rounded-full border border-transparent px-4 py-2.5 text-sm text-black duration-200 ease-in hover:bg-black hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:col-start-2;
}

.slideOverContent {
  @apply relative mt-6 flex-1 px-4 sm:px-6;
}

.baseTableCell {
  @apply whitespace-nowrap py-3 px-2 text-sm;
}

.link {
  @apply hover:bg-green cursor-pointer border-b border-black duration-300 ease-in-out;
}

table {
  @apply mb-8 w-full;
}

th {
  @apply mb-1.5 px-1.5 text-sm uppercase tracking-wide;
}

td {
  @apply py-2.5 px-1.5 text-sm;
}
